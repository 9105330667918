<template>
    <div>
      <Sedirector_app_bar/>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="กำหนดการปฏิทินการสมัคร คัดเลือก ตำแหน่ง รองผู้อำนวยการวิทยาลัย"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" md="12">
                <v-timeline :dense="$vuetify.breakpoint.smAndDown">

                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          กำหนดการ <span class="red--text">ประกาศสมัคร</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodSedirectorRegis/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
                  
                    
                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         สอจ. (ตรวจสอบคุณสมบัติ) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <periodVecprovincesSedirectorRegis/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>


                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประกาศรายชื่อ<span class="red--text">ผู้มีสิทธิ์เข้ารับการคัดเลือก</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodSedirectorNotice/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
                  
  
                             
               


                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         วิทยาลัยต้นสังกัด รายงานผล ภาค ก ระดับสถานศึกษา
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                             <PeriodVecprovincesSedirectorAssess/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
  
                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประกาศรายชื่อ<span class="red--text">ผู้ผ่านการประเมินภาค ก.</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodSedirectorNoticeA/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>


                  <v-timeline-item color="info lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="info lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         สอจ. รายงานผล ภาค ข สำนักงานอาชีวศึกษาจังหวัด
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <PeriodVecprovincesSedirectorAssessB/>

                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>



                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประเมิน ภาค ค<span class="red--text">สัมภาษณ์</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodSedirectorNoticeC/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>


                  <v-timeline-item color="grey lighten-1" fill-dot left small>
                    <v-card>
                      <v-card-title class="grey lighten-1 justify-end">
                        <h2 class="mr-4 white--text font-weight-light">
                          ประกาศ<span class="red--text">ผลการคัดเลือก</span> 
                        </h2>
                        <v-icon dark size="42">
                          mdi-calendar
                        </v-icon>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <PeriodSedirectorNoticeS/>
                          </v-col>                       
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>

  

                  <v-timeline-item color="success lighten-1" fill-dot right>
                    <v-card>
                      <v-card-title class="success lighten-1">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         รายงานตัวเลือกสถานศึกษา
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                            <periodSedirectorChooseCollege/>

                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>





  
                  <v-timeline-item color="purple lighten-2" fill-dot left>
                    <v-card>
                      <v-card-title class="purple lighten-2">
                        <v-icon dark size="42" class="mr-4">
                          mdi-calendar
                        </v-icon>
                        <h2 class="white--text font-weight-light">
                         วิทยาลัยปลายทาง (รับรายงานตัว) 
                        </h2>
                      </v-card-title>
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="12">
                              <periodCollegeSedirectorReport/>
                          </v-col>                     
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-timeline-item>
  
  
              
                </v-timeline>
              </v-col>
            </v-row>
          </v-card>
        </base-material-card>
      </v-container>
    </div>
  </template>
  
  <script>
import PeriodSedirectorNotice from '../../../components/admin/periodSedirectorNotice.vue';
import PeriodSedirectorNoticeA from '../../../components/admin/periodSedirectorNoticeA.vue';
import PeriodSedirectorNoticeC from '../../../components/admin/periodSedirectorNoticeC.vue';
import PeriodSedirectorNoticeS from '../../../components/admin/periodSedirectorNoticeS.vue';
import periodSedirectorChooseCollege from '../../../components/admin/periodSedirectorChooseCollege.vue';
import periodCollegeSedirectorReport from '../../../components/admin/periodCollegeSedirectorReport.vue';


import PeriodSedirectorRegis from '../../../components/admin/periodSedirectorRegis.vue';
import PeriodVecprovincesSedirectorAssess from '../../../components/admin/periodVecprovincesSedirectorAssess.vue';
import PeriodVecprovincesSedirectorAssessB from '../../../components/admin/periodVecprovincesSedirectorAssessB.vue';

import periodVecprovincesSedirectorRegis from '../../../components/admin/periodVecprovincesSedirectorRegis.vue';
import Sedirector_app_bar from '../../../components/admin/sedirector_app_bar.vue';




  
  
  export default {
      name: "HRvecTransferTeachers",
      data() {
          return {
              loading: true,
              ApiKey: "HRvec2021",
          };
      },
      mounted() { },
      methods: {},
      components: { PeriodSedirectorRegis, periodVecprovincesSedirectorRegis, PeriodVecprovincesSedirectorAssess, PeriodSedirectorNotice, PeriodSedirectorNoticeA, PeriodVecprovincesSedirectorAssessB, PeriodSedirectorNoticeC, PeriodSedirectorNoticeS, periodSedirectorChooseCollege, periodCollegeSedirectorReport, Sedirector_app_bar }
    }
  </script>
  
  <style lang="scss" scoped></style>
  